import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'


import activeReports from '../components/activeReports';
import default1 from '../components/default1';
import test from '../components/testme';

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'default1',
    component: default1

  },

  {
    path: '/jobReports/:url',
    name: 'jobReports',
    component: activeReports
  },
  {
    path: '/test/:name',
    name: 'test',
    component: test
  },
  {
    path: '/employeeReport/:url',
    name: 'jobReports',
    component: activeReports
  },
  {
    path: '/job-report/:url',
    name: 'job-report',
    component: activeReports
  }
]

// mode: 'history',
// base: process.env.BASE_URL,
const router = new VueRouter({
  routes
})

export default router
